html,
body,
#root {
	height: 100%;
}

.pf-v5-c-masthead {
	--pf-v5-global--BackgroundColor--dark-100: #1e2939;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link {
	font-weight: bold;
}

.centered {
	padding-left: 20%;
	padding-right: 20%;
	--pf-v5-c-page__main-section--PaddingRight: 20%;
	--pf-v5-c-page__main-section--PaddingLeft: 20%;
}
